import { Fab, LinearProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import { navigate } from 'components/Link';
import { greenDarkPh, redDarkPh, yellowDarkPh } from 'controlTower/chartUtils/colors';
import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import BottomNavSales from 'sales/BottomNavSales';
import { StringParam, useQueryParam } from 'use-query-params';
import { useCurrencyFormatter, useDate, useSettings, useUser } from 'utils/customHooks';
import fetch from 'utils/fetch';
import {
  format0,
  format1,
  format2,
  formatCurrencyNum0,
  formatDayTime24,
  formatTime,
  isVal,
} from 'utils/utils';
import TopNav from './TopNav';

const colorConfig = {
  daily: {
    ACHIEVED_TARGET: (data) => {
      let value = data?.salesValue / data?.ptmAverage || 0;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if ((data.salesValue !== 0 && !data.salesValue) || !data?.ptmAverage) color = 'grey';
      return color;
    },
    SALESMEN_PRESENT: (data) => {
      let value = data?.reportedCount || 0;
      let color = value >= 1 ? greenDarkPh : redDarkPh;
      if (data.reportedCount != 0 && !data.reportedCount) color = 'grey';
      return color;
    },
    CALL_COMPLIANCE: (data) => {
      let value =
        (data?.dashboard?.visits.value + data?.dashboard?.passedBy.value) /
        data?.dashboard?.total.value;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if (!data?.dashboard?.total.value) color = 'grey';
      return color;
    },
    P1M_ACTIVE_STORES: (data) => {
      let value = data?.activeBeatRetailersCount / data?.beatRetailersCount || 0;
      let color = value > 0.9 ? greenDarkPh : value > 0.8 ? yellowDarkPh : redDarkPh;
      if (
        (data.activeBeatRetailersCount !== 0 && !data.activeBeatRetailersCount) ||
        !data?.beatRetailersCount
      )
        color = 'grey';
      return color;
    },
    PRODUCTIVITY: (data) => {
      let value = data?.productive / data?.dashboard?.total?.value || 0;

      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if ((data.productive !== 0 && !data.productive) || !data?.dashboard?.total?.value)
        color = 'grey';
      return color;
    },
    DGP: (data) => {
      let value = data?.dgpAchieved / data?.dgpTarget || 0;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.dgpAchieved !== 0 && !data.dgpAchieved) || !data.dgpTarget) color = 'grey';
      return color;
    },
    PREVIOUS_DGP: (data) => {
      let value = data?.prevVisitDgpAchieved / data?.prevVisitDgpTarget || 0;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if (
        (data.prevVisitDgpAchieved !== 0 && !data.prevVisitDgpAchieved) ||
        !data.prevVisitDgpTarget
      )
        color = 'grey';
      return color;
    },
    IGP: (data) => {
      let value = data?.igpAchieved / data?.igpTarget || 0;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.igpAchieved !== 0 && !data.igpAchieved) || !data.igpTarget) color = 'grey';
      return color;
    },
  },
  MTD: {
    ACHIEVED_TARGET: (data) => {
      let value = Math.round(
        (data?.sales * 100) /
          (((data?.monthlySalesTarget || 0) * data?.beatDaysCount) / data?.totalWorkingDaysCount)
      );
      let color = value > 80 ? greenDarkPh : value > 70 ? yellowDarkPh : redDarkPh;
      if ((data.sales !== 0 && !data.sales) || !data?.monthlySalesTarget) color = 'grey';
      return color;
    },
    SALESMEN_PRESENT: (data) => {
      let value = data?.reportedCount / data?.beatDaysCount || 0;
      let color = value >= 1 ? greenDarkPh : redDarkPh;
      if ((data.reportedCount !== 0 && !data.reportedCount) || !data.beatDaysCount) color = 'grey';
      return color;
    },
    CALL_COMPLIANCE: (data) => {
      let value = (data?.visited + data?.passedBy) / data?.total;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if (!data?.total) color = 'grey';
      return color;
    },
    P1M_ACTIVE_STORES: (data) => {
      let value = data?.activeRetailersCount / data?.allBeatRetailersCount || 0;
      let color = value > 0.9 ? greenDarkPh : value > 0.8 ? yellowDarkPh : redDarkPh;
      if (
        (data.activeRetailersCount !== 0 && !data.activeRetailersCount) ||
        !data?.allBeatRetailersCount
      )
        color = 'grey';
      return color;
    },
    PRODUCTIVITY: (data) => {
      let value = data?.productiveCount / data?.total;
      let color = value > 0.8 ? greenDarkPh : value > 0.7 ? yellowDarkPh : redDarkPh;
      if ((data.productiveCount !== 0 && !data.productiveCount) || !data?.total) color = 'grey';
      return color;
    },
    DGP: (data) => {
      let value =
        data?.dgpAchieved / data?.dgpTarget -
        (data?.prevMonthDgpAchieved / data?.prevMonthDgpTarget) *
          (data?.beatDaysCount / data?.totalWorkingDaysCount);
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.dgpAchieved !== 0 && !data.dgpAchieved) || !data.dgpTarget) color = 'grey';
      return color;
    },
    PREVIOUS_DGP: (data) => {
      let value = data?.prevDateDgpAchieved / data?.prevDateDgpTarget || 0;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.prevDateDgpAchieved !== 0 && !data.prevDateDgpAchieved) || !data.prevDateDgpTarget)
        color = 'grey';
      return color;
    },
    IGP: (data) => {
      let value =
        data?.igpAchieved /
          ((data?.igpTarget * data?.beatDaysCount) / data?.totalWorkingDaysCount) || 0;
      let color = value >= 0.9 ? greenDarkPh : redDarkPh;
      if ((data.igpAchieved !== 0 && !data.igpAchieved) || !data.igpTarget) color = 'grey';
      return color;
    },
  },
};

const useStyles = makeStyles((theme) => ({
  fabExpand: {
    position: 'fixed',
    bottom: 80,
    right: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: 50,
    borderRadius: 25,
    transform: 'rotate(90deg)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  fabMinimize: {
    position: 'fixed',
    bottom: 80,
    right: 20,
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    width: 50,
    borderRadius: 25,
    transform: 'rotate(-90deg)',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
}));

const SalesRepView = ({ stlId: stlIdParam }) => {
  const date = useDate();
  const [tab = 'daily', setTab] = useQueryParam('tab', StringParam);
  const [idType = 'stlId'] = useQueryParam('idType', StringParam);

  let user = useUser();
  const id = stlIdParam || user.id;

  const { data, status } = useQuery(date && id && ['salesRep_stl', { date, stlId: id, tab }], () =>
    fetch(
      `/salesman/activities/${
        tab === 'daily' ? 'dashboard' : 'mtdDashboard'
      }?date=${date}&${idType}=${id}`
    )
  );

  const [expandCards, setExpandCards] = useState(false);

  const classes = useStyles();

  return (
    <div class="bg-salesGray pb-20">
      {status === 'loading' ? <LinearProgress color="secondary" /> : null}
      <TopNav onChange={setTab} value={tab} />
      {data ? (
        <>
          <div class="grid md:grid-cols-2 lg:grid-cols-3 gap-3 p-5 pt-0">
            {data
              .sort((salesmanA, salesmanB) => salesmanB.score - salesmanA.score)
              .map((salesman, index) => (
                <SalesmanCard
                  salesman={salesman}
                  key={salesman.id}
                  expandCards={expandCards}
                  isMTD={tab}
                  index={index}
                ></SalesmanCard>
              ))}
          </div>
          <Fab
            aria-label="btn"
            className={`${expandCards ? classes.fabMinimize : classes.fabExpand}`}
            variant="circular"
            onClick={() => setExpandCards(!expandCards)}
          >
            {expandCards ? <DoubleArrowIcon /> : <DoubleArrowIcon />}
          </Fab>
        </>
      ) : null}
      <BottomNavSales index={1} />
    </div>
  );
};

function SalesmanCard({ salesman, expandCards, isMTD, index }) {
  const { id } = salesman;
  const [expandSingle, setExpandSingle] = useState({ status: false, id: null });

  const formatCurrency = useCurrencyFormatter();

  const {
    salesDashboardCardFields,
    monthlySalesDashboardCardFields: monthlySettings,
    salesDashboardColorCalculation,
  } = useSettings();

  const monthlySalesDashboardCardFields = monthlySettings ?? salesDashboardCardFields;

  const details = {
    DISTANCE: (key) => (
      <Details2
        key={key}
        heading="Distance"
        v1={format1(salesman?.dashboard?.distanceTravelled?.value / 1000) + ' km'}
        l1="Total"
        v2={format1(salesman?.dashboard?.inMarketDistance?.value / 1000) + ' km'}
        l2="Market"
      ></Details2>
    ),

    MARKET_TIMING: (key) => (
      <Details2
        key={key}
        heading={`${isMTD === 'daily' ? 'Market Timing' : ''}`}
        v1={
          isMTD === 'daily'
            ? salesman?.marketEntryTime
              ? formatDayTime24(new Date(salesman?.marketEntryTime))
              : '--'
            : ''
        }
        l1={`${isMTD === 'daily' ? 'Entry' : ''}`}
        v2={
          isMTD === 'daily'
            ? salesman?.marketExitTime
              ? formatDayTime24(new Date(salesman?.marketExitTime))
              : '--'
            : ''
        }
        l2={`${isMTD === 'daily' ? 'Exit' : ''}`}
      ></Details2>
    ),

    TIME_SPENT: (key) => (
      <Details2
        key={key}
        heading="Time Spent"
        v1={salesman?.timeInMarket ? formatTime(salesman?.timeInMarket / 1000) : '--'}
        l1="In Market"
        v2={salesman?.inStoreTime ? formatTime(salesman?.inStoreTime / 1000) : '--'}
        l2="In Store"
      ></Details2>
    ),

    PRODUCTIVITY: (key) => (
      <Details1
        key={key}
        heading="Productivity"
        v={
          isMTD === 'daily'
            ? `${(salesman.productive || 0).toLocaleString('en-US')} / ${(
                salesman.beatRetailersCount || 0
              ).toLocaleString('en-US')} (${Math.round(
                ((salesman.productive || 0) * 100) / salesman.beatRetailersCount
              )}%)`
            : `${
                salesman.total
                  ? `${(salesman.visited + salesman.passedBy).toLocaleString(
                      'en-US'
                    )} / ${salesman.total.toLocaleString('en-US')} (${Math.round(
                      ((salesman.visited + salesman.passedBy) * 100) / salesman.total
                    )}%)`
                  : `${(salesman.visited + salesman.passedBy).toLocaleString('en-US')} / 0 (0%)`
              }`
        }
        color={colorConfig[isMTD]['PRODUCTIVITY'](salesman)}
      ></Details1>
    ),

    SALES_PRODUCTIVITY: (key) => (
      <Details1
        key={key}
        heading="Productivity"
        v={
          isMTD === 'daily'
            ? `${(salesman.productive || 0).toLocaleString('en-US')} / ${(
                salesman.dashboard.total.value || 0
              ).toLocaleString('en-US')} (${Math.round(
                ((salesman.productive || 0) * 100) / salesman.dashboard.total.value
              )}%)`
            : `${
                salesman.total
                  ? `${salesman.productiveCount} / ${salesman.total} (${format0(
                      (salesman.productiveCount * 100) / salesman.total
                    )}%)`
                  : `${salesman.productiveCount} / ${isVal(salesman.total) ? salesman.total : '--'}`
              }`
        }
        color={colorConfig[isMTD]['PRODUCTIVITY'](salesman)}
      ></Details1>
    ),

    LINES: (key) => (
      <Details2
        key={key}
        heading="Lines"
        v1={isMTD === 'daily' ? salesman?.totalLines : salesman?.linesCut}
        l1="Total"
        v2={format1(
          isMTD === 'daily' ? salesman?.avgLinesPerOrder : salesman?.linesCut / salesman?.billsCut
        )}
        l2="Av./Bill"
      ></Details2>
    ),

    ACHIEVED_TARGET: (key) => (
      <Details1
        key={key}
        heading="Sales"
        v={`${
          isMTD === 'daily'
            ? `${Math.round(salesman.salesValue || 0).toLocaleString('en-US')} / ${Math.round(
                salesman.ptmAverage
              ).toLocaleString('en-US')} (${
                salesman.ptmAverage
                  ? Math.round(((salesman.salesValue || 0) * 100) / salesman.ptmAverage)
                  : 100
              }%)`
            : `${Math.round(salesman.sales || 0).toLocaleString('en-US')} / ${Math.round(
                ((salesman.monthlySalesTarget || 0) * salesman.beatDaysCount) /
                  salesman.totalWorkingDaysCount
              ).toLocaleString('en-US')} (${
                salesman.monthlySalesTarget
                  ? Math.round(
                      ((salesman.sales || 0) * 100) /
                        ((salesman.monthlySalesTarget * salesman.beatDaysCount) /
                          salesman.totalWorkingDaysCount)
                    )
                  : 100
              }%)`
        }`}
        color={colorConfig[isMTD]['ACHIEVED_TARGET'](salesman)}
      ></Details1>
    ),

    SALES_ACHIEVED_TARGET: (key) => (
      <Details1
        key={key}
        heading="Sales"
        v={`${
          isMTD === 'daily'
            ? `${Math.round(salesman.salesValue || 0).toLocaleString('en-US')} / ${Math.round(
                salesman.ptmAverage
              ).toLocaleString('en-US')} (${
                salesman.ptmAverage
                  ? Math.round(((salesman.salesValue || 0) * 100) / salesman.ptmAverage)
                  : 100
              }%)`
            : `${formatCurrencyNum0(salesman.sales ?? 0)} / ${
                isVal(salesman.monthlySalesTarget)
                  ? formatCurrencyNum0(salesman.monthlySalesTarget)
                  : '--'
              } ${
                salesman.monthlySalesTarget
                  ? '(' +
                    Math.round(((salesman.sales || 0) * 100) / salesman.monthlySalesTarget) +
                    '%)'
                  : ''
              }`
        }`}
        color={colorConfig[isMTD]['ACHIEVED_TARGET'](salesman)}
      ></Details1>
    ),

    SALES: (key) => (
      <Details2
        key={key}
        heading="Sales"
        l1="Net Value"
        v1={formatCurrency(salesman.factoredNetSalesValue ?? 0)}
        l2="Index Value"
        v2={
          isMTD === 'daily'
            ? formatCurrency(salesman.salesValue || 0)
            : formatCurrency(salesman.sales ?? 0)
        }
        // color={colorConfig[isMTD]['ACHIEVED_TARGET'](salesman)}
      ></Details2>
    ),
    UNIQUE_BRANDS: (key) => (
      <Details1
        key={key}
        heading="Brands per day"
        v={salesman.uniqueOrderBrands}
        // color={colorConfig[isMTD]['ACHIEVED_TARGET'](salesman)}
      ></Details1>
    ),
    SALESMEN_PRESENT: (key) => (
      <Details1
        key={key}
        heading="Sales Rep Present"
        v={
          isMTD === 'daily'
            ? salesman?.reportedCount || 0
            : `${salesman.reportedCount.toLocaleString(
                'en-US'
              )} / ${salesman.beatDaysCount.toLocaleString('en-US')} (${Math.round(
                (salesman.reportedCount * 100) / salesman.beatDaysCount
              )}%)`
        }
        color={colorConfig[isMTD]['SALESMEN_PRESENT'](salesman)}
      ></Details1>
    ),
    P1M_ACTIVE_STORES: (key) => (
      <Details1
        key={key}
        heading="P1M active store"
        v={
          isMTD === 'daily'
            ? `${salesman.activeBeatRetailersCount.toLocaleString(
                'en-US'
              )} / ${salesman.beatRetailersCount.toLocaleString('en-US')} (${Math.round(
                (salesman.activeBeatRetailersCount * 100) / salesman.beatRetailersCount
              )}%)`
            : `${(salesman.activeRetailersCount || 0).toLocaleString('en-US')} / ${(
                salesman.allBeatRetailersCount || 0
              ).toLocaleString('en-US')} (${Math.round(
                (salesman.activeRetailersCount * 100) / salesman.allBeatRetailersCount
              )}%)`
        }
        color={colorConfig[isMTD]['P1M_ACTIVE_STORES'](salesman)}
      ></Details1>
    ),
    ASSORTMENT_ADHERENCE: (key) => (
      <Details1
        key={key}
        heading="Suggested Assortment Adherence"
        v={salesman?.assortmentAdherence}
        color={null}
      ></Details1>
    ),
    CALL_COMPLIANCE: (key) => (
      <Details1
        key={key}
        heading="Call Compliance"
        v={
          isMTD === 'daily'
            ? `${(
                salesman.dashboard.visits.value + salesman.dashboard.passedBy.value
              ).toLocaleString('en-US')} / 
      ${salesman.dashboard.total.value.toLocaleString('en-US')} (${Math.round(
                ((salesman.dashboard.visits.value + salesman.dashboard.passedBy.value) * 100) /
                  salesman.dashboard.total.value
              )}%)`
            : `${
                salesman.total
                  ? `${(salesman.visited + salesman.passedBy).toLocaleString(
                      'en-US'
                    )} / ${salesman.total.toLocaleString('en-US')} (${Math.round(
                      ((salesman.visited + salesman.passedBy) * 100) / salesman.total
                    )}%)`
                  : `${(salesman.visited + salesman.passedBy).toLocaleString('en-US')} / 0 (0%)`
              }`
        }
        color={colorConfig[isMTD]['CALL_COMPLIANCE'](salesman)}
      ></Details1>
    ),
    EDGE_SCORE: (key) => (
      <Details1
        key={key}
        heading="Edge Score"
        v={format1(salesman.linesCut / salesman.linesTarget)}
        color={null}
      ></Details1>
    ),
    SALESMAN_SCORE: (key) => (
      <Details1 key={key} heading="Salesman Score" v={format2(salesman?.salesmanScore)}></Details1>
    ),
    DGP: (key) => (
      <Details1
        key={key}
        heading={isMTD === 'daily' ? "Today's DGP 3.0" : 'MTD DGP 3.0'}
        v={
          salesman?.dgpTarget
            ? `${salesman.dgpAchieved} / ${salesman.dgpTarget} (${format0(
                (salesman?.dgpAchieved * 100) / salesman?.dgpTarget
              )}%)`
            : `${salesman.dgpAchieved} / 0 (0%)`
        }
        color={colorConfig[isMTD]['DGP'](salesman)}
      ></Details1>
    ),
    PREVIOUS_DGP: (key) => (
      <Details1
        key={key}
        heading={isMTD === 'daily' ? 'Previous Routes DGP 3.0' : "Yesterday's DGP 3.0"}
        v={
          isMTD === 'daily'
            ? salesman?.prevVisitDgpTarget
              ? `${salesman?.prevVisitDgpAchieved} / ${salesman.prevVisitDgpTarget} (${format0(
                  (salesman?.prevVisitDgpAchieved * 100) / salesman?.prevVisitDgpTarget
                )}%)`
              : `${salesman.prevVisitDgpAchieved} / 0 (0%)`
            : salesman?.prevDateDgpTarget
            ? `${salesman?.prevDateDgpAchieved} / ${salesman.prevDateDgpTarget} (${format0(
                (salesman?.prevDateDgpAchieved * 100) / salesman?.prevDateDgpTarget
              )}%)`
            : `${salesman?.prevDateDgpAchieved} / 0 (0%)`
        }
        color={colorConfig[isMTD]['PREVIOUS_DGP'](salesman)}
      ></Details1>
    ),
    IGP: (key) => (
      <Details1
        key={key}
        heading="IGP"
        v={
          salesman?.igpTarget
            ? `${salesman.igpAchieved} / ${salesman.igpTarget} (${format0(
                (salesman?.igpAchieved * 100) / salesman?.igpTarget
              )}%)`
            : `${salesman.igpAchieved} / 0 (0%)`
        }
        color={colorConfig[isMTD]['IGP'](salesman)}
      ></Details1>
    ),
    VISIT_COMPLIANCE: (key) => (
      <Details2
        key={key}
        heading="Call Compliance"
        v1={
          salesman.beatOrderRetailersCount -
          salesman.beatAwayOrderRetailersCount +
          (salesman.beatFailedOrderRetailersCount - salesman.beatAwayFailedOrderRetailersCount)
        }
        l1="Geo-compliant"
        v2={salesman.beatAwayOrderRetailersCount + salesman.beatAwayFailedOrderRetailersCount}
        l2="Non-geo Compliant"
      ></Details2>
    ),
    NOT_VISITED: (key) => (
      <Details1
        key={key}
        heading="Not Visited"
        v={
          salesman.beatRetailersCount -
          salesman.beatOrderRetailersCount -
          salesman.beatFailedOrderRetailersCount
        }
      ></Details1>
    ),
    THROUGHPUT: (key) => (
      <Details1
        key={key}
        heading="Throughput / Visit"
        v={formatCurrencyNum0(salesman?.sales / salesman?.productiveCount)}
      ></Details1>
    ),
    TOTAL_BRANDS: (key) => (
      <Details1 key={key} heading="Total BPD" v={salesman.totalOrderBrands}></Details1>
    ),
    MAN_DAY: (key) => (
      <Details1 key={key} heading="ManDay Score" v={salesman?.manDayCount || 0}></Details1>
    ),
  };
  let count = 0;
  if (colorConfig[isMTD]['ACHIEVED_TARGET'](salesman) == redDarkPh) count++;
  if (colorConfig[isMTD]['P1M_ACTIVE_STORES'](salesman) == redDarkPh) count++;
  if (colorConfig[isMTD]['PRODUCTIVITY'](salesman) == redDarkPh) count++;
  if (colorConfig[isMTD]['CALL_COMPLIANCE'](salesman) == redDarkPh) count++;
  if (colorConfig[isMTD]['DGP'](salesman) == redDarkPh) count++;
  let cardColor = count > 1 ? '#F87171' : '#34D399';

  if (salesDashboardColorCalculation?.score) {
    cardColor = salesman.score < salesDashboardColorCalculation?.score ? '#F87171' : '#34D399';
    // cardColor = salesman.score < 0.6 ? '#F87171' : '#34D399';
  }

  const cardView = (cardId) => {
    if (!expandSingle.status) setExpandSingle({ status: true, id: cardId });
    else {
      setExpandSingle({ status: false, id: null });
    }
  };

  useEffect(() => {
    if (!expandCards) setExpandSingle({ status: false, id: null });
  }, [expandCards]);

  const openCards = 'flex';
  const cardFields = isMTD === 'MTD' ? monthlySalesDashboardCardFields : salesDashboardCardFields;

  return (
    <div
      onClick={() => cardView(id)}
      class={`bg-white rounded-lg shadow h-full divide-y p-3 pt-2 pb-0 pr-0 border-4`}
      style={{ borderColor: cardColor }}
    >
      <div class="grid grid-cols-2 justify-between">
        <div>
          <p class="font-semibold">{`${salesman?.code} ${salesman?.name}`}</p>
          {isMTD === 'MTD' ? (
            expandCards || (expandSingle.status && expandSingle.id === id) ? null : (
              <div>
                <p
                  class="font-semibold"
                  style={{ color: colorConfig[isMTD]['ACHIEVED_TARGET'](salesman) }}
                >
                  Sales : {formatCurrency(salesman?.sales)} /{' '}
                  {formatCurrency(
                    ((salesman?.monthlySalesTarget || 0) * salesman?.beatDaysCount) /
                      salesman?.totalWorkingDaysCount
                  )}
                </p>
              </div>
            )
          ) : (
            <p class={`pt-1 text-grey`}>{`Beat: ${salesman?.todayMktName ?? ''}`}</p>
          )}
          {isMTD === 'daily' ? (
            expandCards || (expandSingle.status && expandSingle.id === id) ? (
              <div class={`items-center flex`}>
                <p
                  class={`bg-${
                    salesman?.dashboard?.mobileStatus?.value == 'on' ? 'green-500' : 'gray-400'
                  } text-tiny text-white w-12 rounded text-center mt-1 py-0.5`}
                >
                  {salesman?.dashboard?.mobileStatus?.value == 'on' ? 'Online' : 'Offline'}
                </p>
                <p
                  class={`${
                    salesman?.reportedCount === 1 ? 'text-green-500' : 'text-red-500'
                  } ml-2 text-lg font-semibold`}
                >
                  {salesman?.reportedCount === 1 ? 'P' : 'A'}
                </p>
              </div>
            ) : null
          ) : null}
        </div>
        <div class={`grid grid-cols-2`}>
          {expandCards || (expandSingle.status && expandSingle.id === id) ? (
            <div class="text-center font-semibold">
              {cardFields.includes('NOT_VISITED_HEADER') && (
                <>
                  <p class="text-grey">
                    {(isMTD === 'daily'
                      ? salesman?.dashboard?.notVisited.value
                      : salesman?.total - (salesman.passedBy + salesman?.visited)) ?? '--'}
                  </p>
                  <p class="text-tiny text-grey">Not Visited</p>
                </>
              )}
              {cardFields.includes('PASSED_BY_HEADER') && (
                <>
                  <p class={`pt-1 text-${yellowDarkPh}`}>
                    {isMTD === 'daily' ? salesman?.dashboard?.passedBy.value : salesman?.passedBy}
                  </p>
                  <p class="text-tiny text-grey">Passed By</p>
                </>
              )}
            </div>
          ) : (
            <div></div>
          )}
          <div
            class={`flex flex-col ${
              expandCards || (expandSingle.status && expandSingle.id === id)
                ? 'items-center'
                : 'items-end mr-2'
            }`}
          >
            <div
              class={`rounded-full h-10 w-10 flex items-center cursor-pointer justify-center text-white font-semibold text-lg mb-2`}
              style={{ backgroundColor: cardColor }}
              onClick={() => navigate(`/sales/Retailers/${id}`)}
            >
              {index + 1}
            </div>
            {cardFields.includes('VISITED_HEADER') && (
              <>
                <div
                  class={`${
                    expandCards || (expandSingle.status && expandSingle.id === id)
                      ? openCards
                      : 'hidden'
                  } justify-center`}
                >
                  <p class="text-xl font-semibold">
                    {isMTD === 'daily' ? salesman?.dashboard?.visits.value : salesman?.visited}
                  </p>
                  <p class="text-xl">{`/${
                    isMTD === 'daily' ? salesman?.dashboard?.total.value : salesman?.total
                  }`}</p>
                </div>
                <p
                  class={`${
                    expandCards || (expandSingle.status && expandSingle.id === id)
                      ? openCards
                      : 'hidden'
                  } text-tiny text-grey `}
                >
                  Visited
                </p>
              </>
            )}
          </div>
        </div>
      </div>
      {expandCards || (expandSingle.status && expandSingle.id === id) ? (
        <div class={`grid grid-cols-3 gap-3 py-3`}>
          {cardFields?.map((field) => details[field]?.(field))}
        </div>
      ) : null}
    </div>
  );
}

function Details1({ heading, v, color }) {
  return (
    <div class="text-center" style={{ color: color }}>
      <p class="text-xs font-semibold text-grey">{heading}</p>
      <p class="text-xsTiny font-bold mt-1">{v}</p>
    </div>
  );
}

function Details2({ heading, l1, v1, l2, v2 }) {
  return (
    <div class="text-center">
      <p class={`text-xs font-semibold text-grey`}>{heading}</p>
      <div class="pt-1 grid grid-cols-2 gap-2">
        <div class="text-right">
          <p class="text-xsTiny font-bold">{v1}</p>
          <p class="text-tiny font-semibold text-grey">{l1}</p>
        </div>
        <div class="text-left">
          <p class="text-xsTiny font-bold">{v2}</p>
          <p class="text-tiny font-semibold text-grey">{l2}</p>
        </div>
      </div>
    </div>
  );
}

export default SalesRepView;
