import { Router } from '@reach/router';
import Layout from 'components/Layout';
import React from 'react';
import { ReactQueryConfigProvider } from 'react-query';
import SalesRepView from 'sales/SalesRepView';

export default (props) => (
  <Layout {...props} hideNodeSelector title="Sales Rep">
    <ReactQueryConfigProvider
      config={{ suspense: false, throwOnError: false, useErrorBoundary: false }}
    >
      <Router>
        <SalesRepView path="/sales/salesRepView" />
        <SalesRepView path="/sales/salesRepView/:stlId" />
      </Router>
    </ReactQueryConfigProvider>
  </Layout>
);
